import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";

import { Button } from "@material-ui/core";

import { headerContainerStyles, headerTextStyles, optionsContainerStyles, subHeaderTextStyles } from "js/react/views/Onboarding/MarketingSurveyStyles";

import Container from "./Components/Container";

const fadeInKeyframes = keyframes`
    from {
        opacity: 0;
        transform: translateX(40%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const fadeOutKeyframes = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const HeaderContainer = styled.div`
    ${headerContainerStyles}
`;

const HeaderText = styled.div`
    ${headerTextStyles}
`;

const SubHeaderText = styled.h3`
    ${subHeaderTextStyles}
`;

const OptionsContainer = styled.div`
    ${optionsContainerStyles}
    grid-template-columns: repeat(6, 1fr);

    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
    }
`;

const OptionButton = styled(Button)`
    width: 85px;
    padding: 10px;
    border-radius: 1px;
    background: #FFF;
    opacity: 0;
    animation: ${props => props.fadeOut ? css`${fadeOutKeyframes} 0.5s ease-in-out` : css`${fadeInKeyframes} 0.6s cubic-bezier(0.2, 0, 0, 1) forwards`};
    animation-delay: ${props => props.delay && !props.fadeOut ? `${props.delay}s` : "0s"};
    animation-fill-mode: forwards;
    will-change: opacity;
`;

const OptionName = styled.span`
    color: #999999;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
`;

const CompanySize = ({ onNext, onBack, prevProgress, currProgress, handleChange, value, paneIdx, prevPaneIdx }) => {
    const [selectedOption, setSelectedOption] = useState(value);
    const [progress, setProgress] = useState(prevProgress);
    const [fadeOut, setFadeOut] = useState(false);

    const calculateDelay = index => {
        return 1 / (1 + Math.exp(-0.25 * index)) * 0.8;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setProgress(currProgress);
        }, 0);

        return () => clearTimeout(timer);
    }, []);

    const handleOptionClick = (key, value) => {
        handleChange(key, value);
        setSelectedOption(value);
        setFadeOut(true);
        onNext();
    };

    const handleBackButtonClick = () => {
        setFadeOut(true);
        onBack();
    };

    return (
        <Container showBackButton onBackButtonClick={handleBackButtonClick} progress={progress} prevProgress={prevProgress} paneIdx={paneIdx} prevPaneIdx={prevPaneIdx}>
            <HeaderContainer>
                <HeaderText data-test-id="company-size-header" fadeOut={fadeOut} delay={calculateDelay(0)}>How big is your company?</HeaderText>
                <SubHeaderText fadeOut={fadeOut} delay={calculateDelay(1)}>This helps us personalize your experience</SubHeaderText>
            </HeaderContainer>
            <OptionsContainer>
                <OptionButton
                    onClick={() => handleOptionClick("companySize", "1")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "1" ? "2px solid #11A9E2" : "0.5px solid #999",
                        height: "39px"
                    }}
                    delay={calculateDelay(2)}
                >
                    <OptionName>Just me</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("companySize", "2-19")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "2-19" ? "2px solid #11A9E2" : "0.5px solid #999",
                        height: "39px"
                    }}
                    delay={calculateDelay(3)}
                >
                    <OptionName>2-19</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("companySize", "20-49")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "20-49" ? "2px solid #11A9E2" : "0.5px solid #999",
                        height: "39px"
                    }}
                    delay={calculateDelay(4)}
                >
                    <OptionName>20-49</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("companySize", "50-99")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "50-99" ? "2px solid #11A9E2" : "0.5px solid #999",
                        height: "39px"
                    }}
                    delay={calculateDelay(5)}
                >
                    <OptionName>50-99</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("companySize", "100-199")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "100-199" ? "2px solid #11A9E2" : "0.5px solid #999",
                        height: "39px"
                    }}
                    delay={calculateDelay(6)}
                >
                    <OptionName>100-199</OptionName>
                </OptionButton>
                <OptionButton
                    onClick={() => handleOptionClick("companySize", "200+")}
                    fadeOut={fadeOut}
                    style={{
                        border: selectedOption === "200+" ? "2px solid #11A9E2" : "0.5px solid #999",
                        height: "39px"
                    }}
                    delay={calculateDelay(7)}
                >
                    <OptionName>200+</OptionName>
                </OptionButton>
            </OptionsContainer>
        </Container>
    );
};

export default CompanySize;
